import camelCase from "camelcase"

export function addFeedbackToInput(context, inputKey, errors) {
  // NOTE: Strip prefix for nested attributes, e.g.
  //   'recipient.bank_account_number' => 'bank_account_number'
  const inputName = inputKey.replace(/^\w+\./, "")
  const inputTarget = context[`${camelCase(inputName)}Target`]

  if (inputTarget === undefined) return

  const el = document.createElement("div")

  el.classList.add("invalid-feedback")
  el.textContent = errors.join(", ")

  inputTarget.after(el)
}

export function removeFeedbackFromInputs() {
  document.querySelectorAll(".invalid-feedback").forEach((el) => el.remove())
}
