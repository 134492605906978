import "./modules/nearest_organization"
import "./modules/apartment_valuation_filtering"

import Parsley from "parsleyjs"

import { parsleyI18nSV } from "./modules/parsley_i18n_sv"

export { default as ApplicationController } from "./controllers/application_controller"
export { default as WelcomeController } from "./controllers/welcome_controller"
export { default as OrganizationsController } from "./controllers/organizations_controller"
export { default as AddressesController } from "./controllers/addresses_controller"
export { default as OrgDashboardController } from "./controllers/board_room/org_dashboard_controller"
export { default as OrgContractController } from "./controllers/board_room/org_contract_controller"
export { default as BrfContactController } from "./controllers/organizations/brf_contact_controller"
export { default as OrgLoanController } from "./controllers/board_room/org_loan_controller"
export { default as OrgInsuranceController } from "./controllers/board_room/org_insurance_controller"
export { default as WallsController } from "./controllers/walls_controller"
export { default as SubscriptionsController } from "./controllers/subscriptions_controller"
export { default as ApartmentValuationController } from "./controllers/apartment_valuation_controller"
export { default as BrfanalysSubscriptionController } from "./controllers/profile/settings/brfanalys_subscription_controller"
export { default as BrfanalysDownloadController } from "./controllers/brfanalys_download_controller"
export { default as BrfanalysPurchaseController } from "./controllers/brfanalys_purchase_controller"
export { default as BrfanalysSubscriptionPurchaseController } from "./controllers/brfanalys_subscription_purchase_controller"
export { default as BrfanalysComponentController } from "./controllers/brfanalys_component_controller"
export { default as ResidentPaymentsController } from "./controllers/profile/settings/resident_payments_controller"
export { default as OrgFoldersController } from "./controllers/board_room/org_folders_controller"
export { default as OrgDocumentsController } from "./controllers/board_room/org_documents_controller"
export { default as InfiniteScrollController } from "./controllers/board_room/infinite_scroll_controller"
export { default as KlarnaOrderController } from "./controllers/brfanalys_klarna_order_controller"
export { default as OrganizationTagsController } from "./controllers/board_room/organization_tags_controller"

window.Parsley = Parsley

Parsley.addMessages("sv", parsleyI18nSV())
Parsley.setLocale("sv")
