import "core-js/stable"
import "regenerator-runtime/runtime"

import "element-closest-polyfill"
import "custom-event-polyfill"
import "es6-object-assign"
import "babel-polyfill"
import "whatwg-fetch"
import "chartkick"
import { Application } from "stimulus"
import Rails from "@rails/ujs"
import "../stylesheets/application.scss"

import BankidAuthController from "../controllers/bankid_auth_controller"
import EmailAuthController from "../controllers/email_auth_controller"
import EmailVerificationAuthController from "../controllers/email_verification_auth_controller"
import OnboardingCampaignController from "../controllers/onboarding_campaign_controller"
import FeatureCampaignController from "../controllers/feature_campaign_controller"

import * as allabrf from "../../../engines/allabrf/app/webpack_assets/application"
import * as brfdata from "../../../engines/brfdata/app/webpack_assets/application"
import * as anbudskollen from "../../../engines/anbudskollen/app/webpack_assets/application"
import * as signer from "../../../engines/signer/app/webpack_assets/application"
import * as register from "../../../engines/register/app/webpack_assets/application"
import * as broker_snapshots from "../../../engines/broker_snapshots/app/webpack_assets/application"
import * as bokslut from "../../../engines/bokslut/app/webpack_assets/application"

import "../../../engines/shared_assets/app/webpack_assets/application"

window.Rails = Rails

const application = Application.start()

application.register("bankid-auth", BankidAuthController)
application.register("email-auth", EmailAuthController)
application.register("email-verification-auth", EmailVerificationAuthController)
application.register("onboarding-campaign", OnboardingCampaignController)
application.register("feature-campaign", FeatureCampaignController)

application.register("allabrf-application", allabrf.ApplicationController)
application.register("welcome", allabrf.WelcomeController)
application.register("organizations", allabrf.OrganizationsController)
application.register("addresses", allabrf.AddressesController)
application.register("org-dashboard", allabrf.OrgDashboardController)
application.register("org-contract", allabrf.OrgContractController)
application.register("org-folders", allabrf.OrgFoldersController)
application.register("org-documents", allabrf.OrgDocumentsController)
application.register("infinite-scroll", allabrf.InfiniteScrollController)
application.register("brf-contact", allabrf.BrfContactController)
application.register("org-loan", allabrf.OrgLoanController)
application.register("org-insurance", allabrf.OrgInsuranceController)
application.register("organization-tags", allabrf.OrganizationTagsController)
application.register("walls", allabrf.WallsController)
application.register("subscriptions", allabrf.SubscriptionsController)
application.register("klarna-order", allabrf.KlarnaOrderController)
application.register(
  "apartment-valuation",
  allabrf.ApartmentValuationController
)

application.register("brfanalys-download", allabrf.BrfanalysDownloadController)
application.register("brfanalys-purchase", allabrf.BrfanalysPurchaseController)
application.register(
  "brfanalys-subscription-purchase",
  allabrf.BrfanalysSubscriptionPurchaseController
)
application.register(
  "brfanalys-subscription",
  allabrf.BrfanalysSubscriptionController
)
application.register(
  "brfanalys-component",
  allabrf.BrfanalysComponentController
)

application.register("brfdata-application", brfdata.ApplicationController)

application.register(
  "estate-agents-application",
  broker_snapshots.ApplicationController
)

application.register("resident-payments", allabrf.ResidentPaymentsController)

application.register("document-signing", signer.DocumentSigningController)
application.register(
  "add-organization-data",
  signer.AddOrganizationDataController
)
application.register("tenant-signing", signer.ParkingApplicationSigning)

application.register(
  "tenant-invoice-list",
  register.TenantInvoiceListController
)
application.register(
  "tenant-invoice-delivery",
  register.TenantInvoiceDeliveryController
)
application.register("tenant-parkings", register.TenantParkingsController)

application.register("board-invoice-list", register.BoardInvoiceListController)
application.register(
  "board-properties-register",
  register.BoardPropertiesRegisterController
)
application.register(
  "board-members-register",
  register.BoardMembersRegisterController
)
application.register(
  "board-tenants-register",
  register.BoardTenantsRegisterController
)
application.register("board-results", register.BoardRegisterResultsController)
application.register(
  "board-account-statement",
  register.BoardRegisterAccountStatementController
)
application.register(
  "board-register-parking",
  register.BoardRegisterParkingsController
)
application.register(
  "board-register-parking-garage",
  register.BoardRegisterParkingGarageController
)
application.register(
  "board-register-parking-storage",
  register.BoardRegisterParkingStorageController
)
application.register(
  "board-finance-balance",
  register.BoardFinanceBalanceController
)
application.register(
  "board-finance-debit",
  register.BoardFinanceDebitController
)

application.register(
  "board-customer-fees",
  register.BoardCustomerFeesController
)

application.register(
  "board-customer-payments",
  register.BoardCustomerPaymentsController
)

application.register("board-budget-year", register.BoardBudgetYearController)

application.register(
  "board-budget-multiple-year",
  register.BoardBudgetMultipleYearController
)

application.register(
  "board-budget-analysis",
  register.BoardBudgetAnalysisController
)
application.register(
  "board-expenses-history",
  register.BoardExpensesHistoryController
)
application.register("board-expenses-new", register.BoardExpensesNewController)
application.register("board-payout-requests", register.BoardPayoutRequestsController)
application.register("board-payout-requests-form", register.BoardPayoutRequestsFormController)
application.register("board-payout-requests-modal", register.BoardPayoutRequestsModalController)
application.register(
  "board-surcharges-history",
  register.BoardSurchargesHistoryController
)
application.register(
  "board-surcharges-new",
  register.BoardSurchargesNewController
)

application.register(
  "anbudskollen-application",
  anbudskollen.ApplicationController
)
application.register("anbudskollen-welcome", anbudskollen.WelcomeController)
application.register("anbudskollen-pages", anbudskollen.PagesController)

application.register(
  "broker-transfer",
  broker_snapshots.BrokerTransferController
)

application.register("bokslut-board-room", bokslut.BoardRoomController)
application.register("bokslut-attachments", bokslut.AttachmentsController)

Rails.start()
