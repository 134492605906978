import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  addFeedbackToInput,
  removeFeedbackFromInputs,
} from "../../../../../shared_assets/app/webpack_assets/shared/form_validation_feedback"
import camelCase from "camelcase"

export default class extends ApplicationController {
  static targets = [
    "form",
    "success",
    "failure",
    "recipientInput",
    "boardMember",
    "individualRecipientType",
    "legalEntityRecipientType",
    "nameLabel",
    "name",
    "numberLabel",
    "number",
    "address",
    "zipcode",
    "town",
    "bankName",
    "bankClearingNumber",
    "bankAccountNumber",
    "payoutType",
    "expenseType",
    "amountLabel",
    "amount",
    "taxPercentageContainer",
    "taxPercentageLabel",
    "taxPercentage",
    "taxDeductionContainer",
    "taxDeduction",
    "description",
    "document",
  ]

  connect() {
    this._toggleExpenseInputs()
  }

  success(event) {
    let [data, _status, _xhr] = event.detail

    if (data.message !== undefined && data.message !== "") {
      this.successTarget.innerText = data.message
    }

    this.failureTarget.classList.add("hidden")
    this.successTarget.classList.remove("hidden")

    removeFeedbackFromInputs()
    this.formTarget.reset()
    this._toggleExpenseInputs()

    window.scrollTo(0, 0)
  }

  failure(event) {
    let [data, _status, _xhr] = event.detail

    if (data.message !== undefined && data.message !== "") {
      this.failureTarget.innerText = data.message
    }

    this.failureTarget.classList.remove("hidden")
    window.scrollTo(0, 0)

    removeFeedbackFromInputs()

    if (data.errors !== undefined) {
      for (var key in data.errors) {
        addFeedbackToInput(this, key, data.errors[key])
      }
    }
  }

  fillRecipientInputs(event) {
    const recipientId = event.currentTarget.selectedOptions[0].value
    const recipientData = event.currentTarget.selectedOptions[0].dataset

    if (recipientId !== "") {
      this.boardMemberTarget.value = ""
      this.boardMemberTarget.disabled = true

      this.legalEntityRecipientTypeTarget.disabled = false
      this.nameTarget.disabled = false

      for (var key in recipientData) {
        if (key === "recipient_type") {
          this.resetRecipientTypeInputs(
            this[`${camelCase(recipientData[key])}RecipientTypeTarget`]
          )
        } else {
          this[`${camelCase(key)}Target`].value = recipientData[key]
        }
      }
    } else {
      this.recipientInputTargets.forEach((target) => {
        target.value = ""
      })

      this.resetRecipientTypeInputs()

      this.boardMemberTarget.disabled = false
    }
  }

  resetRecipientTypeInputs(targetToCheck) {
    const target = targetToCheck || this.individualRecipientTypeTarget

    target.checked = true
    target.dispatchEvent(new Event("change"))
  }

  fillRecipientInputsWithBoardMemberData(event) {
    const boardMemberData = event.currentTarget.selectedOptions[0].dataset

    if ("ss_number" in boardMemberData) {
      this.resetRecipientTypeInputs()
      this.legalEntityRecipientTypeTarget.disabled = true
    } else {
      this.legalEntityRecipientTypeTarget.disabled = false
    }

    this.nameTarget.value = boardMemberData.name || ""
    this.numberTarget.value = boardMemberData.ss_number || ""
    this.addressTarget.value = boardMemberData.address || ""
    this.zipcodeTarget.value = boardMemberData.postal_code || ""
    this.townTarget.value = boardMemberData.city || ""
  }

  toggleRecipientInputs(event) {
    const recipientType = camelCase(event.currentTarget.value)

    this.nameLabelTarget.innerText = this.nameLabelTarget.dataset[recipientType]
    this.nameTarget.placeholder = this.nameTarget.dataset[recipientType]

    this.numberLabelTarget.innerText = this.numberLabelTarget.dataset[recipientType]
    this.numberTarget.placeholder = this.numberTarget.dataset[recipientType]
  }

  togglePayoutInputs(event) {
    const payoutType = event.currentTarget.selectedOptions[0].value

    if (payoutType === "") {
      this._resetAndToggleTaxPercentageInput({ display: false })
      this._resetAndToggleTaxDeductionInput({ display: false })
    }

    if (payoutType === "expense") {
      this._toggleExpenseInputs()
      this.documentTarget.required = true
    } else {
      this._resetAndToggleExpenseTypeInput({ enable: false })
      this._resetAndToggleTaxDeductionInput({ enable: false })
      this.documentTarget.required = false
    }

    if (payoutType === "fee") {
      this._resetAndToggleTaxPercentageInput({ value: 30, display: true })
      this.descriptionTarget.required = false
    } else {
      this.descriptionTarget.required = true
    }

    if (payoutType === "other") {
      this._resetAndToggleTaxPercentageInput({ display: false })
    } else {
      this.taxPercentageTarget.disabled = false
    }

    this.amountLabelTarget.className = payoutType

    this.taxPercentageLabelTarget.innerText =
      this.taxPercentageLabelTarget.dataset[payoutType]
  }

  fillExpenseInputs(event) {
    const expenseType = event.currentTarget.selectedOptions[0].value
    const expenseTypeData = event.currentTarget.selectedOptions[0].dataset

    if (expenseType === "") {
      this._resetAndToggleTaxPercentageInput({ display: vatRegistered })
    } else {
      this.taxPercentageTarget.value = Math.trunc(expenseTypeData.vat_percentage)
    }
  }

  _toggleExpenseInputs() {
    const isExpense = this.payoutTypeTarget.selectedOptions[0].value == "expense"
    const isVatRegistered = this.taxPercentageContainerTarget.dataset.vatRegistered !== undefined

    this._resetAndToggleExpenseTypeInput({ enable: isExpense })
    this._resetAndToggleTaxPercentageInput({ display: isExpense && isVatRegistered })
    this._resetAndToggleTaxDeductionInput({ display: isExpense && isVatRegistered })

    if (!isExpense) this.amountLabelTarget.className = ''
  }

  _resetAndToggleExpenseTypeInput({ enable }) {
    if (enable) {
      this.expenseTypeTarget.required = true
      this.expenseTypeTarget.disabled = false
    } else {
      this.expenseTypeTarget.value = ""
      this.expenseTypeTarget.required = false
      this.expenseTypeTarget.disabled = true
    }
  }

  _resetAndToggleTaxDeductionInput({ display }) {
    this.taxDeductionTarget.value = ""
    this.taxDeductionTarget.required = display
    this.taxDeductionContainerTarget.hidden = !display
  }

  _resetAndToggleTaxPercentageInput({ value = "", display }) {
    this.taxPercentageTarget.value = value
    this.taxPercentageTarget.required = display
    this.taxPercentageContainerTarget.hidden = !display
  }
}
