import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  addFeedbackToInput,
  removeFeedbackFromInputs,
} from "../../../../../shared_assets/app/webpack_assets/shared/form_validation_feedback"
import Sortable from "sortablejs"

export default class extends ApplicationController {
  static targets = [
    "form",
    "organizationTagId",
    "submit",
    "hr",
    "tags"
  ]

  connect() {
    this.sortable = Sortable.create(this.tagsTarget, {
      group: "shared",
      onEnd: this._onSortableEnd.bind(this),
    })
  }

  success(event) {
    let [data, _status, _xhr] = event.detail

    this.tagsTarget.innerHTML = data.html

    removeFeedbackFromInputs()

    this._resetForm(event)
    this._toggleHorizontalRule({ hide: data.html === "" })
  }

  failure(event) {
    let [data, _status, _xhr] = event.detail

    removeFeedbackFromInputs()

    if (data.errors !== undefined) {
      for (var key in data.errors) {
        addFeedbackToInput(this, key, data.errors[key])
      }
    }
  }

  toggleSubmitButton(event) {
    const organizationTagId = event.currentTarget.selectedOptions[0].value

    this.submitTarget.disabled = organizationTagId === ""

    removeFeedbackFromInputs()
  }

  _resetForm(event) {
    if (event.target === this.formTarget) {
      this.formTarget.reset()
      this.submitTarget.disabled = true
    }
  }

  _toggleHorizontalRule({ hide }) {
    if (hide) {
      this.hrTarget.classList.add("hidden")
    } else {
      this.hrTarget.classList.remove("hidden")
    }
  }

  _onSortableEnd(event) {
    let url = event.item.dataset.path
    let data = {
      "organization_tagging[priority]": event.newIndex + 1,
    }

    this.railsUpdate(url, data)
  }
}
